import { Button, Grid , Chip, Checkbox, Typography, Paper, Modal} from '@material-ui/core';
import { AddBox, AddIcCallOutlined, Check, CheckBox } from '@material-ui/icons';
import {getCources} from '../../data/practice-todo/practiceTodo';
import {addToRevision} from '../../state/action-creators/workspace/index'
import "./style.scss";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedback } from '../../data/feedback';
// import "../../common/common.css"

function CourseSuggestion(props) {
    const dispatch = useDispatch();
    const {skills} = props;
    let inRevision = useSelector(state=>state.workspace.rev);
    // const skills = skillsets.length == 1 ? skillsets[0].split(",") : skillsets;
    const courses = getCources(skills);
    let revList = [] ;//[{sel: true, cousees}];
    courses.map((course, i)=>{
        revList.push( {id: i , sel: true, course: course} )
    })

    const [rev, setRev] = useState(revList);
    const [feeds, setFeeds] = useState();
    const [open, setOpen] = useState(false);

    const addtoRevision = ()=>{
       let tempRev = [...inRevision];
        for(let i =0; i<rev.length; i++) {
            if ( rev[i].sel == true && inRevision.filter(v=> (v.title == rev[i].course.title)).length == 0) {
                tempRev.push(rev[i].course);
            }
        }
        dispatch(addToRevision(tempRev));
        console.log('tempRev:',tempRev);
    }

    const handleFeedback = ()=>{
        let feeds = getFeedback(skills);
        console.log('feeds:', feeds)
        setFeeds(feeds);
        setOpen(true);
    }

    return (
        <Grid container direction='column'  style={{fontSize:'10px'}}> 
            <Grid item>
                <Button style={{marginTop:'-0.5em', marginLeft:'1.8em', marginBottom:'0em'}} color="primary" 
                    onClick={()=>handleFeedback()}
                    >
                    Interviewer Feedback
                </Button>
                <Modal open={open}  onClose={()=>setOpen(false)} >
                    <Paper
                        style={{
                            padding: "20px",
                            outline: "none",
                            minWidth: "70%",
                            width:'50%',
                            marginLeft:'25%',
                            marginTop:'5%',
                            //maxHeight:'70%',
                           // height:'auto; overflow'
                    }}
                    >
                        <div style={{height:'280px', overflowY: 'scroll',}}>
                         {feeds&&feeds.map((item)=><div style={{padding:'20px', margin: "5px 0",fontSize:'1em', boxSizing: "border-box", background:'#d4e3e8' }}>
                            <span style={{fontWeight:'bold'}}>Feedback: </span>{item}</div>)}
                         </div>
                    </Paper>
                </Modal>
            </Grid>
            <h2 style={{marginBottom:'1em'}} className="course-title">Course Recommended</h2>
            <Grid item> 
                {rev.map((item)=>{
                return(
                <div style={{display:'flex'}}>
                    <Checkbox style={{height:'28px'}} checked={item.sel} onChange={
                        ()=> {
                            console.log('item:', item);
                            let temp = [...rev];
                            temp[item.id].sel = !temp[item.id].sel;
                            setRev(temp)
                        }}>

                    </Checkbox>
                    <Chip style={{color:'black', width:'100%', height:'100%', textAlign:'left',padding:'0.2em'}} label={<Typography style={{ fontSize:'12px', whiteSpace: 'normal', wordWrap:'break-word'}}>{item.course.title}</Typography>}></Chip>
                </div>)
                    
                })}
            </Grid>
            <Grid item justifyContent="flex-end" > 
                <div className="btnCommon" style={{marginLeft:'1em', marginTop:'1em'}} onClick={ () => { addtoRevision()}}>
                    Add to Revision
                </div>
            </Grid>
        </Grid>)
    
}

export default CourseSuggestion;
import { Grid } from "@material-ui/core";

import BusinessIcon from '@mui/icons-material/Business';
import { useState } from "react";
import { isMobile } from "../../utils/mobileUtil";
import myImage from '../../assets/img/blog.png';


const ListingTile = (props)=>{
    const { listing , mode, search} = props;

    //console.log('tile search: ', search);
    //let bgColor = job.posting =='internal'?'#e2e6f6':'';
    const showDetails = ()=>{

    }

  const  getHighlightedText = (text, highlight)=> {

       if (!text || !highlight) return text;
        // Split on highlight term and include term into parts, ignore case
        try {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' , color:'red'} : {} }>
                { part }
            </span>)
        } </span>;
        }catch {
            return text;
        }
    }

    return (
        <>
        <Grid container direction="column" className="searchResult" style={{margin: "2px 2px", background: '#e2e6f6', alignItems:'start' }}>
            <Grid container direction="row" alignContent="flex-start" justifyContent="flex-start">
                <Grid container item xs={isMobile?12:7} spacing={0} direction="column">
                    {/* <Grid item > 
                        <a style={{ cursor: 'pointer',  display:'inline-flex', color:'blue', background:'none', textDecoration: 'none'}} target="_blank" onClick={()=>{showDetails()}}><strong>{listing.name}</strong> &nbsp;({listing.desig})</a>
                    </Grid> */}
                    {listing.title&&<Grid item style = {{display:'flex'}}>
                        <div><BusinessIcon style={{marginTop:'-0.4em' , height:'20px', width:'20px'}}/> <span> <strong>&nbsp;{listing.title}</strong></span></div>
                    </Grid>}
                    
                    <Grid item ><strong> {listing.shortDesc} &nbsp;</strong> in  {listing.title}  </Grid>
                    <Grid item > Price: <strong> {listing.price} </strong> &nbsp;&nbsp;| Area:&nbsp;<strong>{listing.area}</strong> </Grid>
                    <Grid item ><strong> Email:&nbsp;</strong> {listing.email} </Grid>
                    <Grid item ><strong> Mobile:&nbsp;</strong> {listing.mobile} </Grid>
                    <Grid item ><strong> Listing Date:&nbsp;</strong> {new Date(listing.date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})} </Grid>
                    <Grid item ><strong> About:&nbsp;</strong> {listing.aboutProp}</Grid>
                </Grid>
                <Grid container item xs={1} spacing={0} direction="column">
                </Grid>
                <Grid container item xs={4} spacing={0} direction="column">
                   <div>
                        <img src={myImage} alt="My Image" />
                    </div>
                </Grid>

            </Grid>
         
            {/* <Grid item container direction="row" spacing={0}>
                <Grid item xs={12}>
                    <listingPanel userId={listing.userId} mode={mode} email={listing.email}></listingPanel>
                </Grid>
            </Grid> */}
        </Grid>
        </>
    )

}


export default ListingTile;
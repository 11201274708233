import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Collapse from "@material-ui/core/Collapse";
import { Collapse } from '@mui/material';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from '@mui/icons-material/AddBox';
import { defaultMenu } from "./menu";
import { hasChildren } from "./utils";
import { Checkbox, Grid } from "@material-ui/core";
import { useEffect } from "react";
// import { loadWSData } from "./dataUtils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchCourses, selectCourseItem } from "../../state/action-creators/workspace";
import { API, fetchData, fetchDataWoToken } from "../../config/apiService";
import DoneIcon from '@mui/icons-material/Done';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "../../utils/mobileUtil";

const Sidebar = (props)=> {
  const dispatch = useDispatch();
  const { expand, col, expandSidebar } = props;

  const workspace = useSelector(state=>state.workspace);

  // useEffect(() => {
  //   getData();
  //   console.log('  dispatch(fetchCourses());')
  // }, []);

  // const getData = () => {
  //     dispatch(fetchCourses());
  // }
  //console.log('sidebar:', workspace.menu);

  return <React.Fragment>
    <Collapse in={expand} timeout="auto" unmountOnExit>
      {/* {workspace.my && workspace.my.map((item, key) =><div key={key}> <MenuItem  item={item} /> </div>)} */}
      {workspace.menu && workspace.menu.map((item, key) =><div className="w-full" key={key}> <MenuItem  item={item} col={col} expandSidebar={expandSidebar} isMobile={isMobile}/> </div>)}
    </Collapse>
    </React.Fragment>

}

const MenuItem = ({ item, col ,expandSidebar}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} col={col} expandSidebar={expandSidebar}/>;
};

const SingleLevel = ({ item, col , expandSidebar }) => {
  const workspace = useSelector(state=>state.workspace);
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = { selected : workspace.selected === item ?
        {
            //background:'#f4f4f1',  
            fontWeight:'bold',
           // fontSize:'1.2em',
            border:'1px',
            borderRadius:'5px',
            //padding:'10px 10px 10px 5px',
            color: 'red',
        } 
        : 
        {
         // fontSize:`${isMobile?'30px':'18px'}`,
          fontWeight: `${item.category === 'gitcode' ? 500 : 600}`,
        }
  }

  const fetchGitCodeData = async(item) => {
    //dispatch(fetchCourses());
    if (item.category==='gitcode') {
      let url = `${API.git}?path=${item.title}`;
      let res = await fetchData(url);
      item.code = res;
      //console.log('fetchCourses::::item', item);
    }
  }

  const fetchSelectedItem = async()=>{
    let res;
    let details;
    //console.log('item:', item)

    if(item._id) {
      res = await fetchData(`${API.workspace}?_id=${item._id}`, "Workspace Item");
      console.log('fetch by id::', res)
      if(res) {
        details = res;
        item.desc = details.desc;
        item.content = details.content;
        item.draw = details.draw;
        item.code = details.code;
        item.level = details.level;
        item.mode = details.mode;
        item.lang = details.lang;
        item.type = details.type;
        item.sample = details.sample;
      }
      //console.log('details:', details)
    } else {
      res = await fetchData(`${API.ws}?group=${item.group}&title=${item.title}`);
      console.log('fetch by group , title::', res)
      if(res && res.length>0 && res[0].items.length > 0) {
        details = res[0].items[0];
        console.log('details:', details)
        item.desc = details.desc;
        item.category = details.category;
        item.level = details.level;
        item.mode = details.mode;
        item.type = details.type;
        item.sample = details.sample;
      }
    }
   
      // category -> group -> title -> desc
      // code -> Array -> Subarray -> desc
  }
      
  const handleRouting = async(item)=>{
                     
    console.log("handleRouting", item.category)

    let routingPath = '/mainview';

    if (item.category ==='md') {
      if(item.path.indexOf('test')>=0) {
        routingPath += 'test/'
      } else if(item.path.indexOf('learn')>=0) {
        let file = `/learn/${item.group}/${item.title}`.toLowerCase().replaceAll(' ', '_');
        routingPath = `${file}`
      } else if(item.path.indexOf('design')>=0) {
        let file = item.source.replaceAll('_', '-').replace('.md','');
        routingPath = `/${file}`
      } else if(item.path.indexOf('ivwprep')>=0) {
        let file = `/interview/${item.group}/${item.title}`.toLowerCase().replaceAll(' ', '_');
        routingPath = `${file}`
      } else if(item.path.indexOf('courses')>=0) {
        let file = `/courses/${item.group}/${item.title}`.toLowerCase().replaceAll(' ', '_');
        routingPath = `${file}`
      }
      
      console.log("routingPath", routingPath);
      history.replace(routingPath);
  } else if(item.category ==='pdf'){
    let sub = 'topic';
    if(item.path.indexOf('learn') >=0 ) {
      sub = 'learn';
    } else if(item.path.indexOf('ivwprep') >= 0) {
      sub = 'interview';
    } else if(item.path.indexOf('courses') >= 0) {
      sub = 'courses';
    }
    let file = `/_${sub}/${item.group}/${item.title}`.toLowerCase().replaceAll(' ', '_');
    routingPath = `${file}`
    console.log("routingPath", routingPath);
    history.push(routingPath);
  }
}

  return (
    <React.Fragment>
      <ListItem style={{fontSize:'4px'}} button  onClick={ async()=>{
        
        console.log('Sidebar Item Clicked:', item);
        if (!localStorage.getItem('token')) {
            console.log(`Signup is required to access ${item.group}, Signup is free`)
            toast.error(`Signup is required to access ${item.group}. Signup is free`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        if(item.routes) {
          //history.push(item.routes);
          //let path = `/${item.group}/${item.routes}`.toLowerCase();
          let path = `/${item.group}`.toLowerCase();
          history.replace(path);
          return;
        }


        //if(!item.group) return;
        if(item.category==='gitcode') {
          await fetchGitCodeData(item);
        }

        if(item.category !=='pdf' && item.category !=='md' &&  !item.desc){
          await fetchSelectedItem(item);
        }
        dispatch(selectCourseItem(item));
        console.log("Is main view", item.category)
        if(item.category) {
          await handleRouting(item);
        }
        }}>


        { item.level === 0?
          // <div style={{marginLeft:'-1em'}} className={item.level === 0 ? "sideBig" : ""} > 
          //   <div  className="sideBarIcon" style={{alignContent:'center'}}>{item.icon}</div>
          //   <div  style={{fontSize:'1.2rem', textAlign: 'center', color: '#007aff'}} >{item.group}</div>
          // </div>
          <div className={item.level === 0 ? "sideBig" : ""} style={{display:'grid', alignContent:'center', justifyContent:'center'}}>
            <div  className="sideBarIcon" style={{alignContent:'center'}}>{item.icon}</div>
            <div  className="text-[14px] md:text-[18px] text-center text-blue-600"  >{item.group}</div>
          </div>
          :<>

          <ListItemIcon >
            {item.icon}
          </ListItemIcon>

          <ListItemText>
            <div className="colorlpurp" style={{marginLeft:'-2em',}}>

              {/* <span style={styles.selected}>{item.title}</span>
                {item.ws == 'my' ? <span style={{fontSize:'0.6em', color:'violet', marginTop:'-1em'}}>ws</span>:''} */}
                {item.ws === 'my' ? <> 
                  <div style={{display:'inline-block', paddingLeft:'0.4em'}}>
                    <span style={styles.selected}>{item.title}</span><DoneIcon style={{marginLeft:'0.5em'}}/>
                  </div>
                </> : <span>{item.title}</span>}
            </div>
          </ListItemText>
          </>
        }
      </ListItem>
    </React.Fragment>
  );
};

const MultiLevel = ({ item, col , expandSidebar}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  //int[] arr = new int[]{2,4,5};
  const handleClick = () => {
    setOpen((prev) => !prev);
    expandSidebar();

    if(item.routes) {
      history.push(item.routes);
    } else if(item.category==='content') {
      dispatch(selectCourseItem(item));
      history.push('/mainview');
    } else { //don't route if selection don't have content
      //history.push('/mainview');
    }
  };


  return (
    <React.Fragment>
      <ListItem  style={{fontSize:'4px'}} button onClick={()=>handleClick()}>
      { item.level === 0?
        <div className={item.level === 0 ? "sideBig" : ""} style={{display:'grid', alignContent:'center', justifyContent:'center'}}>
          <div  className="sideBarIcon" style={{alignContent:'center'}}>{item.icon}</div>
          <div  className="text-[14px] md:text-[18px] text-center text-blue-600"  >{item.group}</div>
        </div>
        :<ListItemIcon className="sideSmall"  style={{marginLeft:'0em'}}>{item.icon}</ListItemIcon>
      }
      {
        (col > 1 || item.level !== 0) &&
          <ListItemText>
            {item.level===0 ? <div className="colorlpurp" style={{marginLeft:'1em', marginTop:'-1.5em'}}><span  style={{fontSize:`${isMobile?'30px':'16px'}`}}>{item.title}</span></div>
              :
              <div className="colorlpurp" style={{marginLeft:'-1.4em'}}><span>{item.title}</span></div>
            }
          </ListItemText> 
      }
      {   (col > 1) && 
          <div style={{marginTop:`${item.level===0?'0px':'0px'}`}}> {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
          
      }
      
      </ListItem>
      {/* {!open&&item.hr==1 && <hr></hr>} */}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container direction="row">
            <Grid item xs={2}>
              {(col > 1) && <span className="divider" />}
            </Grid>
            <Grid item xs={10}>
              <List component="div">
                {children.map((child, key) => (
                  <MenuItem key={key} item={child} col={col} expandSidebar={expandSidebar}/>
                ))}
              </List>
            </Grid>
        </Grid>
      </Collapse>
    </React.Fragment>
  );
};

export default Sidebar;
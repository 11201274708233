import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { fetchData, saveData } from "../../config/apiService";
import { API } from "../../config/apiService";

import { isMobile } from "../../utils/mobileUtil";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import { showErrorToast } from "../../utils/toastMsg";
import InfiniteScroll from "react-infinite-scroll-component";

import ListingTile from "./ListingTile";
import { propListings } from "./listingsData";
// import ScrollComponent from "./ScrollComponent";


const PropSearch = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [listings, setListings] = useState(propListings);
  const clsMenu = `menuNormal fontS-false`
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('robertsgang');

  const left = isMobile ? 12 : 12;
  const iconSize = isMobile ? '40px' : '30px';

  const [pagination, setPagination] = useState(1);
  const [height, setHeight] = useState('75.8vh');


  useEffect(() => {
    // if (q) {  // if query change then need to reset
    // //     setPage(1);
    // //   if (listings.length > 0) {
    // //     setListings([]); // need to reset
    // //   }
    // //   setTimeout( ()=>{searchProfile(true, 1);}, 10);
    
    //   setHeight('75.8vh');
    // }

  }, [])

  const useStyles = makeStyles((theme) => ({
    noBorder: {
      border: "none",
    },
  }));

  const searchProp = async (reset, pageNew) => {
    // let query = "";
    // if(searchOptions.search) {
    //   query = `search=${searchOptions.search}`;
    // }

    // if(searchOptions.sort) {
    //   query = `${query}&sort=${searchOptions.sort}`;
    // }
    let newPage = pageNew || page;  // on query change need to reset page to 1

    //console.log('query::::', q.query, newPage);

    let url = '';
    if (query) {
      url = `${API.resume}/prop?page=${newPage}&limit=10&${query}`
    } else {
      url = `${API.resume}/prop?page=${newPage}&limit=10`;
    }
    setPage(newPage + 1);
    console.log('prop url:', newPage, url);
    //setLoading(true);
    let result = await fetchData(url);
    //setLoading(false);
    //console.log('search result length:', result.length);

    let temp = { ...searchOptions }
    temp['touched'] = null;
    setSearchOptions(temp);


    if (result && result.length > 0) {
      if (reset) {
        setListings(result);
      } else {
        setListings([...listings, ...result]);
      }
    } else if(reset){
      setListings([]);
    }
  }

  const handleProfile = (profile) => {
    //setCurProfile(profile);
    if (!profile.userId) {
      showErrorToast("User Profile Incomplete");
    }
  }

  const refresh = (setItems) => {};

  const classes = useStyles();

  return (<Grid
    item
    container
    direction="row"
    alignItems="flex-start"
    spacing={0}
    style={{ width: '99.9%', marginTop: '0em', display: 'flex', justifyContent: 'center' }}
  >
    <Grid item xs={left} style={{ height: `${isMobile ? '' : 'calc(100vh - 188px)'}`, overflow: 'auto' }} >
      {(listings && listings.length > 0) ? <>
        <Grid item>
          <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '0.2em', marginBottom: '0.4em' }} variant={'h5'}>{`Matching Property listings for '${query}'`}</Typography>
        </Grid>
        <Grid item>
          {
            <InfiniteScroll
                dataLength={listings.length} //This is important field to render the next data
                next={() => {
                  //searchProfile();
                }}
                hasMore={true && listings.length < 500}
                height = 'calc(100vh - 229px)'
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div style={{ minHeight: "100vh" }}>
                  {listings.map((listing, key) => (
                  <Grid container key={key} style={{ background: `${'#f6fbff'}`, marginBottom: '1.7em', borderRadius: '5px', padding: '0.7em 0.5em 0.5em 0.5em' }}>
                      <Grid item xs={12} style={{ marginBottom: '0.4em' }}>
                      </Grid>
                      <ListingTile key={key} listing={listing} mode={"applicant"} search={query} />
                      <br></br>
                  </Grid>)
                  )}
                </div>
            </InfiniteScroll>
          }
        </Grid>

      </>
        :
        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop:'1em'}}>
          {listings && <h3>No Matching Listing</h3>}
        </Grid>}
    </Grid>
  </Grid>)
}

export default PropSearch
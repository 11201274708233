import { useEffect, useState } from "react";

import './SkillTest.scss';
import * as React from 'react';
import { Grid } from "@material-ui/core";
import QuizCatalog from "./QuizTestCatalog";
import SkillTestCatalog from "./SkillTestCatalog";
import ManageInterview from "../interviewer/ManageInterview";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CodeDesignCatalog from "./CodeDesignCatalog";
import { useHistory } from "react-router-dom";

const coinQuiz = 100;
const coinSkill = 200;
const coinCode = 500;
const coinDesign = 1000;

const SkillTestLanding = ()=>{
    const history = useHistory();

    const [viewTest, setViewTest] = useState('');

    const renderAssmntTest = ()=>{
       
        if(viewTest === 'quiz') {
            history.replace(`/test/quiz`);
            return <QuizCatalog setViewTest={setViewTest}></QuizCatalog>
        } else if(viewTest === 'skill') {
            history.replace(`/test/skill`);
            return <SkillTestCatalog setViewTest={setViewTest}></SkillTestCatalog>
        } else if(viewTest === 'code') {
            history.replace(`/test/code`);
            return <CodeDesignCatalog topic="code" coins={coinCode} setViewTest={setViewTest}></CodeDesignCatalog>
        } else if(viewTest === 'design') {
            history.replace(`/test/design`);
            return <CodeDesignCatalog topic="design" coins={coinDesign} setViewTest={setViewTest}></CodeDesignCatalog>
    }
    }
   
    return (
        viewTest === '' ?
        <Grid className='app ' container spacing={0} direction='row'>
        <Grid item xs={12} style={{marginBottom:'1em'}}>
            <h3>Types Of Skills Assessments Provided By Joblient<hr></hr></h3>
            <h4>Let's start your test!</h4>
        </Grid>
            <Grid item xs={'auto'} className="text-[17px] md:text-[42px]">
                <>
                <div className="quiz-section">
                    <button className='button-test' onClick={()=>setViewTest('quiz')}>
                        <span>Quiz Test</span>
                    </button>
                    <span className="text-[10px] md:text-[17px]">{`Gain - ${coinQuiz} points`}</span>
                </div>
                </>
            </Grid>
            <Grid item xs={'auto'}  className="text-[17px] md:text-[42px]">
                <>
                <div className="quiz-section">
                    <button className='button-test' onClick={()=>setViewTest('skill')}>
                        <span>Skill Test</span>
                    </button>
                    <span className="text-[10px] md:text-[17px]" style={{ textAlign: 'center', justifyContent:'center'}}>{`Gain - ${coinSkill} points`}</span>
                </div>
                </>
            </Grid>
            <Grid item xs={'auto'}  className="text-[17px] md:text-[42px]">
                <>
                <div className="quiz-section">
                    <button className='button-test' onClick={()=>setViewTest('code')}>
                        <span>Coding Test</span>
                    </button>
                    <span className="text-[10px] md:text-[17px]">{`Gain - ${coinCode} points`}</span>
                </div>
                </>
            </Grid>
            <Grid item xs={'auto'}  className="text-[17px] md:text-[42px]">
                <>
                <div className="quiz-section">
                    <button className='button-test' onClick={()=>setViewTest('design')}>
                        Design Test
                    </button>
                    <span className="text-[10px] md:text-[17px]">{`Gain - ${coinDesign} points`}</span>
                </div>
                </>
            </Grid>
            <Grid item xs={12} style={{marginTop:'3em'}}>
                <h4>“Almost 90 percent of firms that test job applicants say they will not hire job seekers when pre-employment testing finds them to be deficient in basic skills” - American Management Association</h4>
                <h3>Joblient’s Skills test will aid you in your recruiting endeavours in the following ways:</h3>
            </Grid>
            <Grid item xs={12}>
 
           
                <ul style={{fontSize:'1em', marginLeft:'30%', marginTop:'1em', color:'whitesmoke'}}>
                    <li>Skill tests are tailor made to specific positions which make the hiring process and decision easier and accurate.</li>
                    <li>They are highly valid tests whose results can be seen instantly.</li>
                    <li>They provide an accurate understanding of the skill level a candidate possesses.</li>
                    <li>The cost of a bad hire can be completely avoided by using skill assessments to find the appropriate candidate for your position.</li>
                </ul>
            
        </Grid>
        </Grid> :
        <>
        <div>
            <button className="back" onClick={()=>{setViewTest(''); history.replace(`/test/`)}}><ArrowBackIcon/><span style={{color:'#0a55ad', fontWeight:'bold'}}>Back&nbsp;</span></button>
        </div>
            {renderAssmntTest()}
        </>
        );
    }

export default SkillTestLanding;
import "./App.css";
import "./common/common.css"
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Fragment, useEffect } from "react";
import MainRouter from "./module/home/component/MainRouter";

import Layout from "./module/appbar/Layout";
import { useSelector } from "react-redux";
import IntLayout from "./module/appbar/IntLayout";
import InterviewReport from "./module/interviewer/InterviewReport";
import DefaultShape from "./shape";
import SearchExternal from "./module/job/SearchExternal";
import AssessmentReport from "./module/interviewer/AssessmentReport";
// import('tailwindcss').Config
import TestLayout from "./module/test-window/TestLayout";
import InvalidTest from "./module/test-window/InvalidTest";


function App() {

return <BrowserRouter>
      <Switch>
        <Route exact path="/report/interview*">
          <InterviewReport/>
        </Route>
        <Route exact path="/assess*">
          <AssessmentReport/>
        </Route>
        <Route exact path="/interview*">
          <IntLayout/>
        </Route>
        <Route exact path="/test/:testId/:code">
          <TestLayout />
        </Route>
        <Route exact path="/invalid-test">
          <InvalidTest />
        </Route>
        <Route exact path="/*">
          <Layout/>
        </Route>
      </Switch>
    </BrowserRouter>
 // return <SearchExternal/>
}
export default App;

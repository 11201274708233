import { Grid, List, MenuItem, Paper } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HomeIcon from '@mui/icons-material/Home';
import FeedbackIcon from '@mui/icons-material/Feedback';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuizIcon from '@mui/icons-material/Quiz';
import PersonIcon from '@mui/icons-material/Person';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

const clsMenu = `menuNormal menu fontS-false`

export const displayMenu = (open, setOpen, handleRoute, recruiter, token)=><Paper className="fixed top right-0"
        style={{
        position: "absolute",
        width:'180px',
        float:'right',
        top:'60px',
        marginLeft:'285px',
        padding: "20px",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        minWidth: "30%",
        }}
        >
        <Grid container direction="row">
            <Grid item xs={12}>
                {buildMobileMenu(open, setOpen, handleRoute, recruiter, token)}
            </Grid>
        </Grid>
  </Paper>


export const buildMobileMenu = (open, setOpen, handleRoute, recruiter, token)=><div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_TOP_Q(handleRoute)}
        </div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_QUIZ(handleRoute)}
        </div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_MANGINTVW(handleRoute)}
        </div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_RES(handleRoute)}
        </div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_FDBK(token, handleRoute)}
        </div>
        <div className=' mb-1 cursor-pointer border-b-2 py-2 border-gray-200'>
            {MENU_EMP(open, setOpen, handleRoute, recruiter)}
        </div>
</div>

export const MENU_EMP = (open, setOpen, handleRoute, recruiter)=><Grid item>
        <span className={'menuEmp fontS-false'} style={{width:'12em', verticalAlign:'middle', textAlign:'center'}} onClick={()=>setOpen(!open)} onMouseOver={()=>setOpen(true)} onMouseLeave={()=>setOpen(false)}>
            <BusinessIcon style={{marginLeft:'-1em', marginTop:'-0.2em'}}/> For Employers
            <List button="true" style={{width:'30px', justifyContent:"stretch", marginLeft:'85px', marginTop:'-30px'}} >
                {open ? <ExpandLessIcon  style={{marginLeft:'-1.2em', marginTop:'0.15em',}} /> : <ExpandMoreIcon style={{marginLeft:'-1.2em', marginTop:'0.15em'}}/>}
                {open && recruiter ? empMenu(open, setOpen, handleRoute):<></>}
            </List>
        </span>
    </Grid>


export const MENU_TOP_Q = (handleRoute)=> <Grid item>
        <p className={`${clsMenu}`} onClick={() => handleRoute("/topquestions")}><span><QuizIcon />Top Questions</span></p>
    </Grid>

export const MENU_QUIZ = (handleRoute)=> <Grid item>
        <p className={clsMenu} onClick={() => handleRoute("/quizzes")}><span><QuestionAnswerIcon/>Quizzes</span></p>
    </Grid>

export const MENU_MANGINTVW = (handleRoute)=> <Grid item>
        <p className={clsMenu} onClick={() => handleRoute("/manageinterview")}><span><PersonIcon/>Interviewer</span></p>
    </Grid>

export const MENU_RES = (handleRoute)=> <Grid item>
        <p className={clsMenu} onClick={() => handleRoute("/resumebuilder")}><span><StickyNote2Icon/>&nbsp;Resume</span></p>
    </Grid>

export const MENU_FDBK = (token, handleRoute)=> {
    return token &&<Grid item style={{marginBottom:'1em'}}>
        <p className={clsMenu} onClick={() => handleRoute("/feedback")}><span><FeedbackIcon />Feedback</span></p>
    </Grid>
}

export const MENU_HOME = (handleRoute)=> <Grid item>
        <p  onClick={() =>{
            //props.setShowSBar(true); 
            handleRoute("/home")}}>
            <span>
                <HomeIcon  className="btnLogout" style={{width:'30px', height:'30px', marginRight:'10px', marginTop:'0px',  minWidth:'20px' }}/>
            </span>
        </p>
    </Grid>


const empMenu = (open, setOpen, handleRoute)=> 
<Paper onMouseOver={()=>setOpen(!open)}
    style={{
        padding: "10px",
        outline: "none",
        display: "flex-end",
        flexDirection: "column",
        justifyContent: "center",
        width:'140px',
        alignItems: "right",
        marginLeft:'-120px',
        marginTop: '2px', // `${isMobile?'12px':'4px'}`,
        zIndex:100, 
        background:'rgb(240, 240, 240)',
        position: 'absolute',
        border:'5px',
        //fontSize: `${isMobile?'36px':''}`
       
    
        
    }}
>
{/* <MenuItem onClick={()=>handleRoute("/recruiter")}>Signup</MenuItem> */}
{/* <MenuItem onClick={()=>handleRoute("/logout")}>Logout</MenuItem> */}
{<div  onMouseOver={()=>setOpen(!open)}>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/recruiter")}>Profile</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/addjob")}>Post Job</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/myjobs")}>My Jobs</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/jobapplications")}>Applications</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/applicant")}>Accepted Applicants</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/TestLink")}>Create Test Link</MenuItem>
    <hr/>
    <MenuItem style={{fontSize:'inherit'}} onClick={()=>handleRoute("/testslist")}>Test Details</MenuItem>
</div>
}

</Paper>

import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"

import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import apiList from '../../../config/apiList';
import { fetchData, fetchDataWoToken } from '../../../config/apiService';
// import './styles/css.css'
import './styles/custom.scss'
import './styles/theme.scss'
//import './styles/index.css'
import { useSelector } from "react-redux";
import TopicSelector from "../../interviewPrep/TopicSelector";

import { loadBlobData } from "../../../utils/datautil/blobloader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// import * as fs from 'fs';
// import { readFile } from 'fs'

//import DatePicker from './date-picker';


const MarkDownViewer = ({route})=>{
    const location = useLocation()
    const [md, setMD] = useState();
    const ws = useSelector(state=>state.workspace);
    let selected = ws.selected;

// const md = `
// # DatePicker

// The DatePicker works by supplying a date to bias towards,
// as well as a default timezone.

// <DatePicker biasTowardDateTime="2017-12-05T07:39:36.091Z" timezone="UTC+5" />
// `;
useEffect( ()=>{
    //getData();
    //console.log('ReactDOM.findDOMNode(this):', ReactDOM.findDOMNode(this))
    //ReactDOM.findDOMNode(this).scrollLeft = 0;
    getDataBlob();

    //const root = ReactDOM .createRoot(document.getElementById('root'));

}, [selected])

const getDataBlob = async ()=>{
    let found = false;
    let data =  null;

    checkRoutingMode();

    // USER TO CATCH MD FILES
    // if (localStorage.getItem(selected.source)) {
    //     try{
    //         console.log('CACHE DATA124:', selected.source);
    //         data = JSON.parse(localStorage.getItem(selected.source));
    //         setMD(data);
    //         found = true;
    //     }catch(ex) {
    //         console.log('fetchGITData parse error:', ex);
    //         found = false;
    //     }
    // } 
    console.log('checkRoutingMode::::');
    if (found === false) {
        let url = await loadBlobData(selected.source);
        let res = await fetchDataWoToken(url);
        let tokens = url.split(selected.source);

        console.log('tokens::::', tokens);

        if(res){
            let data = res;           
            let host = `https://joblient.com/api/v1/assets?key=`;
            data = data.replaceAll('assets/', host);
            setMD(data);
        }
        //working for Azure blob
    //     if (res) { 
    //         let data = res;
    //         data = data.replaceAll('assets/', tokens[0]);
    //         data = data.replaceAll('.png', `.png${tokens[1]}`);
    //         data = data.replaceAll('.jpg', `.jpg${tokens[1]}`);
    //         data = data.replaceAll('.gif', `.gif${tokens[1]}`);
    //         setMD(data);
    //         localStorage.setItem(selected.source, JSON.stringify(data));
    //    }
    }
   
}

const checkRoutingMode = ()=>{
    console.log('location.pathname:', location.pathname, route, selected)
    if((selected == undefined || selected == null) && route == 'design') {
        let path = findSource(location.pathname);
        path = path.replace('/','') + '.md';
        console.log('location.path:', path)

        selected = {
            source: path, //"1_file_sharing.md",
            category: "md"
        }
    }
}

const findSource = (pathname)=>{
    let tempPath = '';
    if(pathname.indexOf('design')>=0) {
        tempPath = pathname.replaceAll('-', '_');
        console.log('tempPath:', tempPath);
    }
    return tempPath;
}

return (
    <div className="text-[17px] md:text-[20px] markdown-body md-section">
        <ReactMarkdown
            children={md}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "")
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, "")}
                            style={oneDark}
                            language={match[1]}
                            PreTag="div"
                        />
                    ) : (
                        <code {...props} className={className}>
                            {children}
                        </code>
                    )
                },
            }}
        />
    </div>
)
}


export default MarkDownViewer;
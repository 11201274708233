export let propListings = [
    {
        "title": "Madhupur, Robertsgang",
        "shortDesc": "4 acres, Residential land/ plot",
        "price": "16 Lac",
        "area": "4 acres",
        "address": "Village-sohana, madhupur, Sonebadra",
        "propType": "Residential land",
        "sale": "Resale",
        "aboutProp": "This plot is location at very location just 1km from national highway",
        "email": "xyz@gmail.com",
        "mobile": "999997666",
        "date": "20/11/2024",
        "available": "Available",
        "party": "Direct",
        "imgSource": `mlll`
    },
    {
        "_id": "64ec1748f4fa8f6c2401c3fg",
        "title": "Sukruit, Robertsgang",
        "shortDesc": "4000 sqft, Commercial land/ plot",
        "price": "25 Lac",
        "area": "4000 sqft",
        "address": "Village-Amawa, madhupur, Sonebadra",
        "propType": "Commercial Plot",
        "sale": "New",
        "aboutProp": "Looking for a plot in a prime location of robertsganj? This plot in ormaura is the best deal for you. This 10000 sq.Ft. Super built up area plot is your opportunity to build a exceptional residence. From tue, 25 feb 2025, possession of property can be transferred. The property belongs to owner through freehold ownership. This residential plot is all yours for 16 lac.",
        "email": "xyz@gmail.com",
        "mobile": "999997666",
        "date": "24/11/2024",
        "available": "Sold",
        "party": "Agent",
    },
    {
        "_id": "64ec1748f4fa8f6c2401c4fg",
        "title": "Sukruit, Robertsgang",
        "shortDesc": "4000 sqft, Commercial land/ plot",
        "price": "30 Lac",
        "area": "3000 sqft",
        "address": "Village-Amawa, madhupur, Sonebadra",
        "propType": "Commercial Plot",
        "sale": "New",
        "aboutProp": "Looking for a plot in a prime location of robertsganj? This plot in ormaura is the best deal for you. This 10000 sq.Ft. Super built up area plot is your opportunity to build a exceptional residence. From tue, 25 feb 2025, possession of property can be transferred. The property belongs to owner through freehold ownership. This residential plot is all yours for 16 lac.",
        "email": "xyz@gmail.com",
        "mobile": "999997666",
        "date": "24/11/2024",
        "available": "Sold",
        "party": "Agent",
    }
]
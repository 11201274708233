import React, { createContext, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";

import Welcome, { ErrorPage } from "../component/Welcome";
import Navbar from "../nav/Navbar";
import Login from "../../login/Login";
import Logout from "../../login/Logout";
import Signup from "../../login/Signup";
import Applications from "../component/Applications";
import Profile from "../../login/Profile";
import CreateJobs from "../../recruiter/CreateJobs";
import MyJobs from "../../recruiter/MyJobs";
import JobApplications from "../../recruiter/JobApplications";
import AcceptedApplicants from "../../recruiter/AcceptedApplicants";
import RecruiterProfile from "../../recruiter/RecruiterProfile";
import MessagePopup from "../../login/lib/MessagePopup";
import isAuth, { userType } from "../../login/lib/isAuth";
// import Landing from "../../editor/component/Landing";
import MainView from "../../editor/view-switcher/MainView";
import Register from "../../login/Register";
import BlogView from "../../editor/editor-blog/BlogView";
import ResumeLayout from "../../resume/ResumeLayout";
import ManageInterview from "../../interviewer/ManageInterview";
import IntLayout from "../../appbar/IntLayout";
import { useDispatch, useSelector } from "react-redux";
import { activeInterview, manageInterview } from "../../../state/action-creators/workspace";
import { fetchData, API } from "../../../config/apiService";
import axios from "axios";
import InterviewReport from "../../interviewer/InterviewReport";
import Interview from "../../interviewer/Interview";
import UploadCourse from "../../course/UploadCourse";
import DesignBlog from "../../blogs/design/DesignBlog";
import LeaderBoard from "../../leaderboard/LeaderBord";
import SearchExtResults from "../../job/SearchExtResults";
import HomePage from "../../front/HomePage";
import QueTemplate from "../../interviewPrep/QueTemplate";
import QASetTemplate from "../../interviewPrep/QASetTemplate";
import QTopic from "../../interviewPrep/QSetToipicWise";
import InterviewFeedback from "../../interviewfeedback/InterviewFeedback";
import CandidateSearch from "../../profilesearch/ProfileSearch";
import ProfileSearch from "../../profilesearch/ProfileSearch";
import QuizViewer from "../../editor/editor-quizz/QuizViewer";
import MarkDownViewer from "../../editor/viewer-markdown/MarkDownViewer";
import QuizEditor from "../../interviewrank/QuizTest";
import SkillTestLanding from "../../interviewrank/SkillTestLanding";
import TopSkills from "../../interviewrank/TopSkills";
import QuizMDViewer from "../../editor/viewer-markdown/QuizMDViewer";
import JobSearchBar from "../../job/JobSearchBar";
import UserFeedback from "../../userfeedback/UserFeedback";
import AssessmentReport from "../../interviewer/AssessmentReport";
import HtmlViewer from "../../editor/editor-html/HtmlViewer";
import ViewFeedback from "../../userfeedback/ViewFeedback";
import ViewActivityLogger from "../../userfeedback/ViewActivityLogger";
import ChatListView from "../../chat/ChatListView";
import AssessTestLink from "../../recruiter/testlink/AssessTestLink";
import TestLinkLanding from "../../recruiter/testlink/TestLinkLanding";
import TestsList from "../../recruiter/testlink/TestsList";
import PropSearch from "../../prop/PropSearch";


const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "98vh",
    boxSizing: "border-box",
    width: "100%",
    marginTop: "5vh",
  },
}));

export const SetPopupContext = createContext();

function MainRouter(props) {
  const classes = useStyles();
  const [popup, setPopup] = useState({
    open: false,
    severity: "",
    message: "",
  });


  return (
    <SetPopupContext.Provider value={setPopup}>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/home">
          <HomePage />
        </Route>

        <Route exact path="/htmlviewer">
          <HtmlViewer />
        </Route>
        <Route exact path="/assessreport">
          <AssessmentReport />
        </Route>
        <Route exact path="/mock">
          <QASetTemplate />
        </Route>

        <Route exact path="/topquestions">
          <TopSkills />
        </Route>

        <Route exact path="/leader">
          <LeaderBoard />
        </Route>

        <Route exact path="/profilesearch">
          <ProfileSearch />
        </Route>

        <Route exact path="/jobsearch">
          <SearchExtResults />
        </Route>

        <Route exact path="/test*">
          <SkillTestLanding />
        </Route>

        <Route exact path="/_*">
          <MainView />
        </Route>

        <Route exact path="/quizzes">
          <QuizMDViewer />
        </Route>

        <Route exact path="/chatlist">
          <ChatListView />
        </Route>

        <Route exact path="/mainview">
          <MainView />
        </Route>
        <Route exact path="/design/*">
          <MarkDownViewer route='design' />
        </Route>
        <Route exact path="/learn*">
          <MarkDownViewer route='learn' />
        </Route>
        <Route exact path="/interview*">
          <MarkDownViewer route='learn' />
        </Route>
        <Route exact path="/course*">
          <MarkDownViewer route='learn' />
        </Route>

        <Route exact path="/feedback">
          <UserFeedback />
        </Route>
        <Route exact path="/viewfeedback">
          <ViewFeedback type='admin' />
        </Route>
        <Route exact path="/viewactivity">
          <ViewActivityLogger />
        </Route>

        <Route exact path="/course">
          <UploadCourse />
        </Route>

        <Route exact path="/post">
          {/* <BlogView/> */}
          <DesignBlog />
        </Route>

        <Route exact path="/designblog/:title">
          <DesignBlog />
        </Route>

        <Route exact path="/blogs">
          <HomePage />
        </Route>

        <Route exact path="/interview">
        </Route>
        <Route exact path="/manageinterview">
          {/* <ManageInterview/> */}
          <Interview />
        </Route>
        <Route exact path="/interviewreport">
          <InterviewReport />
        </Route>

        <Route exact path="/login">
          <Login open={true}></Login>
        </Route>

        <Route exact path="/register">
          <Register open={true} />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>

        <Route exact path="/applications">
          <Applications />
        </Route>

        <Route exact path="/jobapplications">
          <JobApplications />
        </Route>

        <Route exact path="/interviewprep">
          <MainView />
        </Route>

        <Route exact path="/profile">
          <Profile />
        </Route>

        <Route exact path="/prop">
          <PropSearch />
        </Route>

        <Route exact path="/recruiter">
          <RecruiterProfile />
          {/* {userType() === "recruiter" ? (
                      <RecruiterProfile/>
                    ) : (
                      <Profile />
                    )} */}

        </Route>
        <Route exact path="/resumebuilder">
          <ResumeLayout />
        </Route>
        <Route exact path="/jobs">
          {/* <SearchResults/> */}
          <SearchExtResults />
        </Route>
        <Route exact path="/jobs/:type">
          {/* <SearchResults/> */}
          <SearchExtResults />
        </Route>

        {userType() === "recruiter" ? <div>
          <Route exact path="/addjob">
            <CreateJobs />
          </Route>
          <Route exact path="/myjobs">
            <MyJobs />
          </Route>
          <Route exact path="/job/application/:jobId">
            <JobApplications />
          </Route>
          <Route exact path="/applicants">
            <AcceptedApplicants />
          </Route>
          <Route exact path="/TestLink">
            <TestLinkLanding />
          </Route>
          <Route exact path="/testsList">
            <TestsList />
          </Route>
        </div>
          :
          <Route>
          </Route>
        }
        <Route>
          <ErrorPage />
        </Route>
      </Switch>
      <MessagePopup
        open={popup.open}
        setOpen={(status) =>
          setPopup({
            ...popup,
            open: status,
          })
        }
        severity={popup.severity}
        message={popup.message}
      />
    </SetPopupContext.Provider>
  );
}

export default MainRouter;

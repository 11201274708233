import { Grid, MenuItem, Modal, TextField} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { useDispatch, useSelector } from "react-redux";
import { userType } from "../login/lib/isAuth";
import { MenuLogout } from "./CustomMenu";
import { loadWorkspace } from "../../utils/datautil/dataUtils";
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { API, fetchData } from "../../config/apiService";
import WidgetsIcon from '@mui/icons-material/Widgets';
import CloseIcon from '@mui/icons-material/Close';
import QuizIcon from '@mui/icons-material/Quiz';


import { MENU_EMP, MENU_HOME, MENU_MANGINTVW, MENU_QUIZ, MENU_RES, MENU_TOP_Q, displayMenu} from "./TopMenu";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const Topbar = (props) => {

    let history = useHistory();
    const location = useLocation()

    const token = useSelector(state=>state.profile.profile.token);
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState(false);
    const [login, setLogin] = useState({flag: false, time: Date.now()});
    let [avetar, setAvetar] = useState('GU');
    const [count, setCount] = useState();

    const clsMenu = `menuNormal menu fontS-false`;
    //console.log('clsMenu:',clsMenu)

    const [sideBar, setSideBar] = useState(false);
    const [showMenu, setShowmenu] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        //localStorage.getItem("token") ? setSignIn(true) : setSignIn(false)
        genAvetar();
        setTimeout(()=> { 
            loadWorkspace(dispatch);
            // if(location.pathname.indexOf('systemdesign')>-1 ) {

            // }
        } , 500) 
        //loadChatBubble();
        console.log('topbar useEffect')
    },[token]) // should refresh on token change


    const handleRoute = (location) => {
      console.log(location);
      history.push(location);
      setMenu(false);
    };

    const genAvetar = ()=>{
        let temp = localStorage.getItem('name');
        if(temp && temp.length>=0) {
            let tokens = temp.split(' ');
            let av = "";
            if(tokens.length ==1) {
                av = tokens[0].substring(0,1);
            } else {
                av = tokens[0].substring(0,1) +  tokens[1].substring(0,1);
            }
            setAvetar(av)
        }
    }
    const handleMenu = () => {
        let tt = Date.now();
        if(tt - login.time>100) {
            setLogin({flag: !login.flag, time: tt})
        }
    }
    const handleTest = () => {
        console.log('handleMenu')
        handleRoute('/test');
    }


    const uploadHandler = ()=>{
        console.log('uploadHandler');
        //uploadBulkCV("CV")
        //loadBlobData();
    }

    const loadChatBubble = async()=>{
        let count = await fetchData(`${API.chat}/new`);
        setCount(count);
        console.log('newMsg:', count)
    }

    const MENU_USER = ()=><Grid item>
        {token &&
            <button className="btnLogout bg-'#e2e2e2" onClick={()=>handleMenu() } >
                <div>
                    <AccountCircleIcon style={{marginTop:'0.3em', transform:'scale(1.4)'}}></AccountCircleIcon>
                </div>

                <div className="text-[10px]" style={{display:'inline-flex',marginTop:'-0.5em', marginLeft:'0.3em', alignItems:'center', justifyContent:'center'}} >
                    <div style={{marginTop:'0.3em', marginLeft:'0.3em'}}>{avetar}
                    </div>
                    <ExpandMoreIcon style={{marginTop:'0.2em'}} />
                </div>
            </button>}
        {login.flag && <MenuLogout login={login} setLogin={setLogin} handleRoute={handleRoute}/>}
    </Grid>

    const MENU_REG = ()=><Grid item>
        <p className={'menuBtn'} onClick={()=>handleRoute("/register")}><span><AccountCircleIcon style={{}}/> Sign Up</span></p>
    </Grid>

    const MENU_SERVICES = ()=><Grid item>
        <span className={'menuEmp fontS-false'} style={{width:'6em', verticalAlign:'middle', textAlign:'center'}}  onClick={()=>setMenu(!menu)}>
            <WidgetsIcon style={{marginLeft:'-1em', marginTop:'-0.2em'}}/>
            <List button="true" style={{width:'30px', justifyContent:"stretch", marginLeft:'20px', marginTop:'-30px'}} >
                {menu ? <CloseIcon  style={{marginLeft:'-1em', marginTop:'0.15em',}} /> : <ExpandMoreIcon style={{marginLeft:'-1em', marginTop:'0.15em'}}/>}
                {/* {menu && displayMenu(open, setOpen, handleRoute, userType() === "recruiter", token)} */}
            </List>
        </span>
    </Grid>

    const MENU_CHAT = ()=><Grid item onClick={() => {handleRoute("/chatList"); loadChatBubble(); }}>
        <div className="header_chat_notification" ></div>
        {count>0&&<div style={{color:'white', marginTop:'-0.1em', marginLeft:'0em',   height:'14px', width:'14px',  border:'8px solid #28d6f0', borderRadius:'100%'}}>
            <div style={{marginTop:'-0.5em', marginLeft:'-0.2em', fontSize:'10px',  textAlign:'center',}} >{count}</div>
        </div>}
    </Grid>


    const MENU_LOGIN = ()=><Grid item>
        <span className={'menuBtn'} onClick={()=>  handleRoute("/login")}>
            <LockOpenIcon/> Sign In
        </span>
    </Grid>

    // const MENU_TEXT = ()=> {
    //     return <TextField 
    //             select
    //             >
    //         <MenuItem>First</MenuItem>
    //         <MenuItem><p className={`${clsMenu}`} onClick={() => handleRoute("/topquestions")}><span><QuizIcon />Top Questions</span></p></MenuItem>
            

    //     </TextField>
    // }

{!token &&<Grid item>
    <span className={'menuBtn'} onClick={()=>  handleRoute("/login")}>
    {/* <img src={signinIcon.img}/>&nbsp;Sign In */}
    <LockOpenIcon/> Sign In
    </span>
</Grid>}

    // const showMobileMenu = ()=><Modal open={menu} onClose={()=>setMenu(!menu)} className='block md:hidden'>
    //     {displayMenu(open, setOpen, handleRoute, userType() === "recruiter", token)}
    // </Modal>

    return(<div>
            {/* MOBILE MENU */}
            <div className='block md:hidden z-[10] py fixed top right-0 text-[20px] mt-0 px bg-white'>
                <Grid  container direction="row" justifyContent="flex-end" spacing ={1} style={{background: 'rgba(240, 237, 237, 0.58)', fontSize:'12px', padding:'10px 0 4px 0',  marginTop:'0px'}}>
                    {MENU_HOME(handleRoute)}
                    {MENU_SERVICES()}
                    {!token && MENU_LOGIN()}
                    {MENU_USER()}
                    {MENU_CHAT()}
                </Grid>
                <div className={`block md:hidden  ${showMenu ? 'mt-0' : 'mt-0'}` }>
                    {menu && displayMenu(open, setOpen, handleRoute, userType() === "recruiter", token)}               
                </div>
            </div>

            {/* DESKTOP MENU */}
            <div className='hidden flex md:block fixed top right-0'>
            <Grid  container direction="row" justifyContent="flex-end" spacing={1} style={{fontSize:'12px', marginTop:'10px'}}>
                {MENU_HOME(handleRoute)}
                {MENU_TOP_Q(handleRoute)}
                {MENU_QUIZ(handleRoute)}
                {MENU_MANGINTVW(handleRoute)}
                {MENU_RES(handleRoute)}
                {!token && MENU_REG()}
                {!token && MENU_LOGIN()}
                {MENU_USER()}
                {MENU_CHAT()}
                <Grid item style={{margin:'5px', borderLeft:'2px solid #adadad'}}>
                </Grid>
                {MENU_EMP(open, setOpen, handleRoute, userType() === "recruiter")}
            </Grid>
            </div>
        </div>

    )
}

export default Topbar;